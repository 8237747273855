import { ThemeUIStyleObject } from 'theme-ui';

export const getContainerStyles = (
  hasDarkBackground?: boolean,
): ThemeUIStyleObject => {
  return {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    'h1, h2, h3, h4, h5, h6, p, li, span, a, a:hover': {
      color: hasDarkBackground ? '#ffffff' : undefined,
    },
    'a:not(:hover)': {
      textDecoration: hasDarkBackground ? 'none' : undefined,
    },
  };
};
