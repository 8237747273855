import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { ImageBlock } from '@sprinklr/shared-lib';
import { Image } from '@sprinklr/shared-lib/@types/entities';
import {
  CONTAINER_STYLES,
  SECTION_LABEL_STYLES,
  LOGO_CONTAINER_STYLES,
  IMAGE_WRAPPER_STYLES,
  IMAGE_SVG_STYLES,
} from './styles';

const SECTION_LABEL = 'with:';

const LogoSection = ({ logos }: { logos: Image[] }) => {
  const { t } = useTranslation();
  return (
    <Flex sx={CONTAINER_STYLES}>
      <Text sx={SECTION_LABEL_STYLES}>{t(SECTION_LABEL)}</Text>
      <Box sx={LOGO_CONTAINER_STYLES}>
        {logos.map(logo => {
          return (
            <Box sx={IMAGE_WRAPPER_STYLES}>
              <ImageBlock image={logo} svgSx={IMAGE_SVG_STYLES} />
            </Box>
          );
        })}
      </Box>
    </Flex>
  );
};

export default LogoSection;
