import { ThemeUIStyleObject } from 'theme-ui';

const BASE_CONTAINER_STYLES: ThemeUIStyleObject = {
  width: '100%',
  maxWidth: '1520px',
  marginInline: 'auto',
  paddingInline: ['24px', '80px'],
};

const INNER_CONTAINER_STYLES: ThemeUIStyleObject = {
  width: ['100%', null, null, '50%'],
  flexDirection: 'column',
  alignItems: ['center', null, null, 'flex-start'],
  textAlign: ['center', null, null, 'start'],
};

export const getStyles = (pt: [number, number], pb: [number, number]) => ({
  container: { ...BASE_CONTAINER_STYLES, pt, pb },
  innerContainer: INNER_CONTAINER_STYLES,
});
