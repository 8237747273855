import React, { useMemo } from 'react';
import { Box } from 'theme-ui';
import { BackgroundImage } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import HeroHeadlines from '../heroTemplate/heroComponents/HeroHeadlines';
import HeroCTAs from '../heroTemplate/heroComponents/HeroCTAs';
import EventInfo from './components/EventInfo';
import CategoryLabel from './components/CategoryLabel';
import LogoSection from './components/LogoSection';
import MainSectionLayout from './components/MainSectionLayout';
import { getContainerStyles } from './styles';
import { Props } from './types';

const EventHeroTemplate = (props: Props) => {
  const {
    backgroundImage,
    categoryLabel,
    ctAs,
    heroHeadline,
    heroSubheadline,
    topMargin,
    bottomMargin,
    logos,
    dateTimeInfo,
    locationInfo,
    hasDarkBackground,
  } = props;

  const [pt, pb] = useContainerPadding({
    top: topMargin ?? 'LARGE',
    bottom: bottomMargin ?? 'LARGE',
  });

  const infoItems = useMemo(
    () =>
      [
        dateTimeInfo
          ? {
              icon: 'calendarIcon',
              label: dateTimeInfo,
            }
          : null,
        locationInfo
          ? {
              icon: 'locationIcon',
              label: locationInfo,
            }
          : null,
      ].filter(infoItem => infoItem !== null),
    [dateTimeInfo, locationInfo],
  );

  const containerStyles = useMemo(
    () => getContainerStyles(hasDarkBackground),
    [hasDarkBackground],
  );

  return (
    <Box sx={containerStyles}>
      {backgroundImage ? (
        <BackgroundImage
          image={backgroundImage?.asset}
          tabletImage={backgroundImage?.tabletAsset}
          mobileImage={backgroundImage?.mobileAsset}
        />
      ) : null}
      <MainSectionLayout pt={pt} pb={pb}>
        <CategoryLabel categoryLabel={categoryLabel} />
        {heroHeadline ? (
          <HeroHeadlines
            text={heroHeadline}
            className="hero-content-headline"
          />
        ) : null}
        {heroSubheadline ? (
          <HeroHeadlines
            text={heroSubheadline}
            isSubHeadline
            className="hero-content-subheadline"
          />
        ) : null}
        {logos ? <LogoSection logos={logos} /> : null}
        {infoItems && infoItems.length > 0 ? (
          <EventInfo infoItems={infoItems} />
        ) : null}
        {ctAs?.length > 0 ? <HeroCTAs ctas={ctAs} /> : null}
      </MainSectionLayout>
    </Box>
  );
};

export default EventHeroTemplate;
