import { ThemeUIStyleObject } from 'theme-ui';

export const HEADLINE_STYLES: ThemeUIStyleObject = {
  color: 'pageType',
  fontSize: [1, 2],
  letterSpacing: '1.2px',
  mt: ['10px', 0],
  fontFamily: 'body',
  fontWeight: 'bold',
  marginBottom: [3, '20px'],
  textTransform: 'uppercase',
};
