import React from 'react';
import { Flex, Text } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import {
  CONTAINER_STYLES,
  ICON_STYLES,
  INFO_ITEMS_CONTAINER_STYLES,
  LABEL_STYLES,
} from './styles';

const EventInfo = ({
  infoItems,
}: {
  infoItems: {
    icon: string;
    label: string;
  }[];
}) => {
  return (
    <Flex sx={CONTAINER_STYLES}>
      {infoItems.map(({ label, icon }) => {
        return (
          <Flex key={label} sx={INFO_ITEMS_CONTAINER_STYLES}>
            <Icon iconName={icon} iconSx={ICON_STYLES} />
            <Text sx={LABEL_STYLES}>{label}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default EventInfo;
