import { ThemeUIStyleObject } from 'theme-ui';

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  flexDirection: 'column',
  gap: '8px',
};

export const INFO_ITEMS_CONTAINER_STYLES: ThemeUIStyleObject = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
};

export const ICON_STYLES: ThemeUIStyleObject = {
  marginRight: '12px',
  height: '30px',
  width: '30px',
  flex: 'none',
};

export const LABEL_STYLES: ThemeUIStyleObject = {
  lineHeight: ['20px', '30px'],
  fontSize: ['16px', '20px'],
};
