import React, { useMemo } from 'react';
import { Box, Flex } from 'theme-ui';
import { getStyles } from './styles';

const MainSectionLayout = ({
  pt,
  pb,
  children,
}: {
  pt: [number, number];
  pb: [number, number];
  children: React.ReactNode;
}) => {
  const styles = useMemo(() => getStyles(pt, pb), [pt, pb]);

  return (
    <Box sx={styles.container}>
      <Flex sx={styles.innerContainer}>{children}</Flex>
    </Box>
  );
};

export default MainSectionLayout;
