import { ThemeUIStyleObject } from 'theme-ui';

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  gap: '10px',
  marginBottom: '64px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: ['center', null, null, 'flex-start'],
};
export const SECTION_LABEL_STYLES: ThemeUIStyleObject = {
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '36px',
};

export const LOGO_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: ['flex', 'grid'],
  gridTemplateColumns: [null, 'repeat(3, auto)'],
  placeItems: [null, 'start'],
  flexWrap: ['wrap', 'initial'],
  justifyContent: ['center', 'initial'],
  gap: '24px',
};

export const IMAGE_WRAPPER_STYLES: ThemeUIStyleObject = {
  flex: '0 0 auto',
  alignItems: 'center',
};

export const IMAGE_SVG_STYLES: ThemeUIStyleObject = {
  height: '24px',
};
