import React from 'react';
import { Heading } from 'theme-ui';
import { HEADLINE_STYLES } from './styles';

const CategoryLabel = ({ categoryLabel }: { categoryLabel: string }) => {
  return (
    <Heading as="h2" sx={HEADLINE_STYLES} className="hero-category-label">
      {categoryLabel}
    </Heading>
  );
};

export default CategoryLabel;
